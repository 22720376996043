<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="8"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Activity Types
            </h1>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-right mb-1"
        md="3"
        cols="4"
      >
        <b-button
          variant="success"
          :to="{ name: 'appointment-activity-types-create' }"
          class="mobile_create_button"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle d-md-block d-none">Create New Activity Type</span>
          <span class="align-middle d-md-none d-block">New</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <a
                href="javascript:void(0)"
                class="nav-link nav-link-search"
                @click="showSearchBar = !showSearchBar"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="21"
                  class="mr-50"
                />
                <span class="align-middle">Search</span>
              </a>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search..."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="searchQuery = ''; showSearchBar = !showSearchBar"
                  />
                </b-input-group-append>
              </b-input-group>

              <b-dropdown
                id="dropdown-form"
                ref="filter_dropdown"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="dropdown-modal"
                right
                variant="link"
              >
                <template #button-content>
                  <feather-icon
                    icon="FilterIcon"
                    size="21"
                    class="align-middle mr-50"
                  />
                  <span class="mr-1">Filters</span>
                </template>
                <activity-type-dropdown-filters
                  :status-filter.sync="statusFilter"
                  :prospect-bookable.sync="prospectBookable"
                  :customer-bookable.sync="customerBookable"
                  :status-options="statusOptions"
                  @emitHideFilter="hideFilter"
                />
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
        <div v-if="prospectBookable || customerBookable || statusFilter.length">
          <hr class="dividerHR filter-divider">
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <ul
                class="list-inline d-inline-block mb-1"
              >
                <li
                  v-if="customerBookable"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="customerBookable = null"
                  >
                    Show in Portal <span class="text-capitalize">({{ customerBookable }})</span>
                  </b-form-tag>
                </li>

                <li
                  v-if="prospectBookable"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="prospectBookable = null"
                  >
                    Customers Only <span class="text-capitalize">({{ prospectBookable }})</span>
                  </b-form-tag>
                </li>
                <li
                  v-for="statusVal in statusFilter"
                  :key="statusVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromStatusFilter(statusVal)"
                  >
                    <span class="text-capitalize">{{ statusVal }}</span>
                  </b-form-tag>
                </li>
                <li
                  class="list-inline-item"
                >
                  <span
                    class="text-danger clear-filter-text mb-50"
                    @click="clearAllFilters"
                  >
                    Clear Filters
                  </span>
                </li>
              </ul>
            </b-col>
          </b-row>
        </div>
      </div>

      <b-table
        ref="refActivityTypesTable"
        class="position-relative min-height-200"
        :items="fetchActivityTypes"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Role -->
        <template #cell(typeID)="data">
          <div class="text-nowrap">
            <b-button
              v-if="canViewThisAction('show', 'ActivityType')"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-black align-middle detail-view-id"
              :to="{ name: 'appointment-activity-types-show', params: { id: data.item._id } }"
            >
              {{ data.item.typeID }}
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-black align-middle detail-view-id not-button"
            >
              {{ data.item.typeID }}
            </b-button>
          </div>
        </template>

        <template #cell(typeName)="data">
          <b-badge
            :variant="`${data.item.color}`"
            class="small-badge-button small-badge-button-activity-list"
            :style="{ '--bg-color': data.item.color }"
          >
            {{ data.item.typeName }}
          </b-badge>
        </template>

        <template #cell(timeSlots)="data">
          <div class="text-nowrap">
            <div v-if="data.item.timeSlots.length">
              <span class="">
                {{ data.item.timeSlots[0].datePeriod }},
                {{ dateFormatFromTimeString(data.item.timeSlots[0].startTime) }}
                -
                {{ dateFormatFromTimeString(data.item.timeSlots[0].endTime) }}
              </span>
              <br>
              <span
                v-for="(day, index) in data.item.timeSlots[0].applicableDays"
                :key="index"
              >
                {{ day }} <span v-if="index + 1 != data.item.timeSlots[0].applicableDays.length">, </span>
              </span>
              <br>
              <span class="">
                {{ data.item.timeSlots[0].timeSlotDuration }} hr, {{ data.item.timeSlots[0].quantityPerSlot }} Slots
              </span>
            </div>
          </div>
        </template>

        <template #cell(prospectBookable)="data">
          <div class="text-nowrap">
            <span class="text-capitalize">{{ data.item.prospectBookable === true ? 'Yes' : 'No' }}</span>
          </div>
        </template>

        <template #cell(customerBookable)="data">
          <div class="text-nowrap">
            <span class="text-capitalize">{{ data.item.customerBookable === true ? 'Yes' : 'No' }}</span>
          </div>
        </template>

        <template #cell(customFields)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.customFields.length }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveActivityTypeStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template
          v-if="canViewThisAction('show', 'ActivityType') || canViewThisAction('update', 'ActivityType') || canViewThisAction('delete', 'ActivityType')"
          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="canViewThisAction('show', 'ActivityType')"
              :to="{ name: 'appointment-activity-types-show', params: { id: data.item._id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('update', 'ActivityType')"
              :to="{ name: 'appointment-activity-types-edit', params: { id: data.item._id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('update', 'ActivityType')"
              id="toggle-btn"
              v-b-modal.modal-update-status
              @click="setActivity(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Update Status</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('delete', 'ActivityType')"
              @click="deleteEntity(data.item._id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
    </b-card>

    <div
      v-if="totalActivityTypes > perPage"
      class="mx-2 mb-2"
    >
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center pagination-content"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalActivityTypes"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            align="center"
            prev-text="Prev"
            next-text="Next"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
              <span>Prev</span>
            </template>
            <template #next-text>
              <span>Next</span>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

    <b-modal
      id="modal-update-status"
      ref="update-status-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="submitForm"
    >
      <b-form @submit.prevent="submitForm">
        <validation-observer
          ref="activityTypeStatusEditForm"
        >
          <b-form-group
            label="Status*"
            label-for="h-activity-type-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-activity-type-status"
                v-model="status"
                label="title"
                :options="statusDataOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="statusValidation == true ? statusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="statusValidation"
                class="text-danger"
              >
                {{ statusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BButton, BCol, BFormInput, BTable, BInputGroup, BInputGroupAppend,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal, BForm, BFormGroup, BFormTag,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import useActivityTypesList from './useActivityTypesList'
import activityTypeStoreModule from './activityTypeStoreModule'
import ActivityTypeDropdownFilters from './ActivityTypeDropdownFilters.vue'

export default {
  components: {
    BInputGroup,
    BInputGroupAppend,
    BCard,
    BRow,
    BButton,
    BCol,
    BFormInput,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BForm,
    BFormGroup,
    BFormTag,

    vSelect,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    ActivityTypeDropdownFilters,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showFilterDropdown: process.env.VUE_APP_SHOW_FILTER_DROPDOWN,
      activityID: '',
      typeID: '',
      name: '',
      status: 'published',
      statusValidation: false,
      statusError: 'Valid status is required',
      statusDataOptions: [
        { title: 'Published', code: 'published' },
        { title: 'Unpublished', code: 'unpublished' },
        { title: 'Set As Draft', code: 'draft' },
      ],

      required,
    }
  },
  methods: {
    setActivity(activity) {
      this.activityID = activity._id
      this.status = activity.status
      this.typeID = activity.typeID
      this.name = activity.typeName
    },
    hideFilter() {
      this.$refs.filter_dropdown.hide(true)
    },

    removeFromStatusFilter(item) {
      const index = this.statusFilter.indexOf(item)
      if (index > -1) {
        this.statusFilter.splice(index, 1)
      }
    },

    clearAllFilters() {
      this.prospectBookable = null
      this.customerBookable = null
      this.statusFilter = []
    },
    submitForm() {
      this.$refs.activityTypeStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.status)

          this.$http.patch(`appointment/activity-types/${this.activityID}/status/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(() => {
              this.refetchData()
              // this.$nextTick(() => {
              //   this.$refs['update-status-modal'].toggle('#toggle-btn')
              // })
              this.$swal({
                title: 'Status Updated!',
                html: `The status for <strong>${this.typeID} ‘${this.name}’</strong> has been updated`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    deleteEntity(id) {
      this.$swal({
        title: 'Are You Sure?',
        html: 'Selecting Delete will <strong>permanently delete</strong> this item. This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Delete!',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.toDeletedID = id
            this.deleteActivityType()
          }
        })
    },
  },
  setup() {
    const showSearchBar = ref(false)
    const showFilterOptions = ref(false)
    const ACTIVITY_TYPE_APP_STORE_MODULE_NAME = 'app-activity-types'

    // Register module
    if (!store.hasModule(ACTIVITY_TYPE_APP_STORE_MODULE_NAME)) store.registerModule(ACTIVITY_TYPE_APP_STORE_MODULE_NAME, activityTypeStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACTIVITY_TYPE_APP_STORE_MODULE_NAME)) store.unregisterModule(ACTIVITY_TYPE_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      { label: 'Published', value: 'published' },
      { label: 'Unpublished', value: 'unpublished' },
      { label: 'Draft', value: 'draft' },
    ]

    const { canViewThisAction } = useAclUtils()

    const {
      fetchActivityTypes,
      tableColumns,
      perPage,
      currentPage,
      totalActivityTypes,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refActivityTypesTable,
      refetchData,

      toDeletedID,
      deleteActivityType,
      currentDataLength,

      // UI
      resolveActivityTypeStatusVariant,

      // Extra Filters
      statusFilter,
      customerBookable,
      prospectBookable,
    } = useActivityTypesList()

    return {

      // Sidebar

      fetchActivityTypes,
      tableColumns,
      perPage,
      currentPage,
      totalActivityTypes,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refActivityTypesTable,
      refetchData,
      showSearchBar,
      showFilterOptions,
      toDeletedID,
      deleteActivityType,

      // Filter
      avatarText,
      canViewThisAction,
      currentDataLength,

      // UI
      resolveActivityTypeStatusVariant,

      statusOptions,

      // Extra Filters
      statusFilter,
      customerBookable,
      prospectBookable,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
